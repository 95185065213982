import './CommercialWithApplicationId.css';

import { parse } from 'qs';
import React, { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Model,
  QuestionMatrixDynamicModel,
  QuestionSelectBase
} from 'survey-core';

import Loading from '../../components/Loading/Loading';
import { useGetJsoNbyApplicationIdQuery } from '../../generated/graphql';
import { Canada, US } from '../../utils/address/province';
import { UnderwritingUrlData } from '../../utils/getUnderwritingUrlData';
import setMinMaxDateForEffectiveDate from '../../utils/setMinMaxDateForEffectiveDate';
import { getValueFromChoicesByLabel } from '../../utils/SurveyJSQuestion';
import { useFlags } from '../../utils/useFlags';
import CommercialSurveyWithApplicationId from './CommercialSurveyWithApplicationId';
import checkStateSupport from './supportedStates';
import { getAmtrustOfficerPanel } from './util';

interface CommercialSurveyWithApplicationIdParams {
  applicationId: string;
}

const CommercialWithApplicationId: React.FC = () => {
  const location = useLocation();
  const { group } = parse(location.search, {
    ignoreQueryPrefix: true
  });

  const {
    applicationId
  } = useParams<CommercialSurveyWithApplicationIdParams>();
  const {
    loading: isgetJSONbyApplicationIdLoading,
    error: getJSONbyApplicationIdError,
    data: getJSONbyApplicationIdData
  } = useGetJsoNbyApplicationIdQuery({
    variables: { applicationId }
  });

  const {
    amTrustMaster,
    amTrustFullFlow,
    allowDatePickerToPickDateUpToSixtyDays
  } = useFlags();

  // note: we want to set up the model once right after the JSON data is found
  // that's why we need to create a boolean so that useMemo will run the setup twice
  // 1) when the flag is false (nothing setup) and 2) setup the model once when the flag is true
  const canModelBeSetup = !!getJSONbyApplicationIdData;

  const model = useMemo(() => {
    if (!canModelBeSetup) {
      return undefined;
    }

    if (!getJSONbyApplicationIdData) {
      throw new Error('Unreachable - JSON data should be defined');
    }

    const {
      getJSONbyApplicationId: {
        json,
        professionList,
        customerInfo,
        state,
        previousAnswers,
        wcData,
        policyStartDateStr,
        carrierPartner,
        displayWcQuestions
      }
    } = getJSONbyApplicationIdData;

    const model = new Model(json);

    // set default value of province/state
    const { province } = location.state as AddressParams;
    const provinceValue =
      (US.isLongName(province) && US.getShortName(province)) ||
      (Canada.isLongName(province) && Canada.getShortName(province));
    if (provinceValue) {
      model.setValue('BusinessInformation_100_BusinessAddress_WORLD_EN', {
        province: provinceValue
      });
    }

    const isStateSupported =
      US.isLongName(state) && checkStateSupport(US.getShortName(state));

    // TODO add helper function
    model.setVariable('isStateSupported', isStateSupported);
    model.setVariable('amTrustMaster', amTrustMaster && displayWcQuestions);
    model.setVariable('amTrustFullFlow', amTrustFullFlow && displayWcQuestions);
    model.setVariable('isEndorsement', false);
    model.setVariable('isBindOnlineAmtrust', false);
    model.setVariable('carrierPartner', carrierPartner);

    const { firstName, lastName } = customerInfo;

    model.setValue(
      'BusinessInformation_100_Profession_WORLD_EN',
      professionList
    );
    model.setValue(
      'BusinessInformation_100_CustomerInfo_WORLD_EN',
      customerInfo
    );
    const currentYear = new Date().getFullYear();
    model.setVariable('currentYear', currentYear);

    const stateQuestion = model.getQuestionByName(
      'BusinessInformation_100_Locations_US_EN'
    );
    if (stateQuestion) {
      stateQuestion.title = `${stateQuestion.title} ${state}?`;
    }

    const stateLocationQuestion = model.getQuestionByName(
      'BusinessInformation_100_State_WORLD_EN'
    );

    if (state && stateLocationQuestion) {
      const stateValue = getValueFromChoicesByLabel(
        state,
        stateLocationQuestion as QuestionSelectBase
      );

      model.setValue('BusinessInformation_100_State_WORLD_EN', stateValue);
    }

    const effectiveDateQuestion = model.getQuestionByName(
      'BusinessInformation_100_EffectiveDate_WORLD_EN'
    );

    if (policyStartDateStr && !effectiveDateQuestion.visible) {
      // set effective date
      model.setValue(
        'BusinessInformation_100_EffectiveDate_WORLD_EN',
        policyStartDateStr
      );
    }

    // endorsement case so wcData will not be defined
    if (previousAnswers) {
      return model;
    }

    if (!wcData) {
      throw new Error('wcData should be defined');
    }

    switch (wcData.__typename) {
      case 'GetJsonWcSuccess': {
        model.data = {
          ...model.data,
          Amtrust_Officers: [
            {
              name: `${firstName} ${lastName}`
            }
          ]
        };

        const { classCodes, rules, isContractor } = wcData;

        const amTrustOfficers = getAmtrustOfficerPanel(model);

        model.setVariable('rules', rules);
        model.setVariable('isContractor', isContractor);
        model.setVariable('amtrustAPIFail', false);

        if (US.isLongName(state)) {
          model.setVariable('UsState', US.getShortName(state));
        }

        const officerClassCode = amTrustOfficers.template.getQuestionByName(
          'duties'
        );

        if (!(officerClassCode instanceof QuestionMatrixDynamicModel)) {
          throw new Error(
            'Unreachable - Amtrust_Officers.duties is not based on a question matrix'
          );
        }

        const employeePayroll = model.getQuestionByName(
          'Amtrust_EmployeePayrollMatrix_US_EN'
        );

        if (!(employeePayroll instanceof QuestionMatrixDynamicModel)) {
          throw new Error(
            'Unreachable - Amtrust_EmployeePayrollMatrix_US_EN is not based on a question matrix'
          );
        }

        const cleanedWcClassCodes = classCodes.map(
          ({ id: { __typename, ...id }, description }) => {
            void __typename; // strip __typename
            return {
              text: description,
              value: id
            };
          }
        );
        officerClassCode.choices = employeePayroll.choices = cleanedWcClassCodes;

        break;
      }
      case 'GetJsonWcError': {
        // switch to broker flow

        const wcDyanmicQuestionPage = model.getPageByName(
          'AmtrustWcAdditionalQuestions'
        );

        const brokerflowPage = model.getPageByName(
          'BrokerWorkersCompensation_0001'
        );

        wcDyanmicQuestionPage.visible = false;
        brokerflowPage.visible = true;

        model.setVariable('amtrustAPIFail', true);

        break;
      }
      case 'GetJsonWcUnavailable': {
        // don't inject anything
        break;
      }
      default:
        throw new Error('Unreachable - unknown wcData response type'); // try updating your GrpahQL schema
    }

    return model;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, canModelBeSetup]); // note: this dependency array means we can only run this twice per application (i.e. before data is ready and after data is ready)

  if (isgetJSONbyApplicationIdLoading) {
    return <Loading />;
  }

  if (getJSONbyApplicationIdError || !getJSONbyApplicationIdData) {
    throw new Error(
      `${
        getJSONbyApplicationIdError
          ? 'getJSONbyApplicationId query error'
          : 'getJSONbyApplicationIdData should be ready'
      }`
    );
  }

  const {
    json,
    previousAnswers,
    policyStartDateStr,
    policyExpiryDateStr,
    professionList,
    transactionType
  } = getJSONbyApplicationIdData.getJSONbyApplicationId;

  if (!json) {
    throw new Error('JSON is not ready before render the form');
  }

  if (!professionList) {
    throw new Error('Could not find the profession');
  }

  if (typeof group !== 'string' && group !== undefined) {
    throw new Error('Unreachable - group type error');
  }

  if (!model) {
    throw new Error('Unreachable - model should be ready at this stage');
  }

  // for endorsement
  if (previousAnswers) {
    const {
      BusinessInformation_100_EffectiveDate_WORLD_EN,
      BusinessInformation_100_Profession_WORLD_EN,
      BusinessInformation_100_CustomerInfo_WORLD_EN,
      BusinessInformation_100_State_WORLD_EN,
      ...previousAnswersWithoutFirstPageAnswers
    } = previousAnswers;

    // Void the unused variables
    void BusinessInformation_100_EffectiveDate_WORLD_EN;
    void BusinessInformation_100_Profession_WORLD_EN;
    void BusinessInformation_100_CustomerInfo_WORLD_EN;
    void BusinessInformation_100_State_WORLD_EN;

    // TODO find a better way to set this flag
    model.setVariable('isEndorsement', true);

    model.setDataCore({
      ...model.data,
      ...previousAnswersWithoutFirstPageAnswers
    });
  }

  setMinMaxDateForEffectiveDate(
    model,
    policyStartDateStr,
    policyExpiryDateStr,
    transactionType,
    allowDatePickerToPickDateUpToSixtyDays
  );

  // for endorsement
  if (previousAnswers) {
    model.runTriggers();
  }

  interface AddressParams {
    country: string;
    province: string;
    brokerCode?: string;
  }
  const { country, province, brokerCode } = location.state as AddressParams;
  const urlData = location.state as UnderwritingUrlData;
  return (
    <div className="h-full Commercial" data-testid="Commercial">
      <CommercialSurveyWithApplicationId
        model={model}
        applicationId={applicationId}
        groupName={group}
        country={country}
        province={province}
        urlData={urlData}
        brokerCode={brokerCode}
      />
    </div>
  );
};

export default CommercialWithApplicationId;
